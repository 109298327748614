import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

// Stylesheet
import styles from "./index.module.scss"

const TributeCard = ({
  data: {
    node: {
      id,
      location,
      sliderContent,
      mom: { firstName, lastName, image },
    },
  },
}) => {
  const name = `${firstName} ${lastName}`
  const url = `${firstName.toLowerCase()}-${lastName.toLowerCase()}/${id}`
  const cardImage = sliderContent?.length > 0 ? sliderContent[0] : image
  return (
    <Link to={`/tribute/${url}`}>
      <div className={styles.tributeCard}>
        <Img
          fluid={cardImage.fluid}
          alt={cardImage.title}
          className={styles.image}
        />
        <div className={styles.details}>
          <h4 className={styles.name}>{name}</h4>
          <span className={styles.address}>{location}</span>
        </div>
      </div>
    </Link>
  )
}

export default TributeCard
