import React, { useEffect, useState, useRef } from "react"
import InputRange from "react-input-range"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import Swal from "sweetalert2"

// Components
import { PinPoint } from "../icons"
import { Dropdown } from "../inputFields"
import useOutsideAlerter from "../../utils/useOutsiteAlerter"
import getDistanceFromLatLonInMiles from "../../utils/distanceCalculator"

// Stylesheet
import styles from "./index.module.scss"
import "react-input-range/lib/css/index.css"

// Images
import {
  SearchIcon,
  LocationIcon,
  SearchDropdownIcon,
  SearchDropdownHoverIcon,
  CancelBtn,
} from "../icons"

const SearchInput = ({
  inputType = "name",
  value,
  error,
  clear,
  clearBtn,
  ...props
}) => (
  <div
    className={`${styles.searchInput} ${
      error ? styles.error : ""
    } h-full flex items-center`}
  >
    {inputType === "name" ? (
      <>
        <SearchIcon className={styles.icon} />
        <SearchIcon hover className={`${styles.icon} ${styles.hovered}`} />
      </>
    ) : (
      <>
        <LocationIcon className={styles.icon} />
        <LocationIcon hover className={`${styles.icon} ${styles.hovered}`} />
      </>
    )}
    <Field
      type="text"
      placeholder={inputType === "name" ? "Enter name" : "Location"}
      className={styles.input}
      {...props}
    />
    {clearBtn && (
      <CancelBtn
        onClick={() => clear(inputType, "")}
        className={styles.cancelBtn}
      />
    )}
  </div>
)

// Sort Filter
const AlphabeticalFilter = ({ data, setTribute, activeLetter, setLetter }) => {
  const list = Array.from({ length: 26 }).map((_, index) =>
    String.fromCharCode(97 + index)
  )

  const filterByAlphabet = letter => {
    const filtered = data.filter(
      el => el.node.mom.firstName.charAt(0).toLowerCase() === letter
    )
    setTribute(filtered)
    setLetter(letter)
  }
  return (
    <div className={`${styles.alphabeticalFilter} flex flex-wrap`}>
      {list.map((letter, index) => (
        <div
          key={index}
          className={`${styles.letter} ${
            letter === activeLetter ? styles.active : ""
          }`}
          onClick={() => filterByAlphabet(letter)}
        >
          {letter}
        </div>
      ))}
    </div>
  )
}
const SearchFilterSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").strict(),
  location: Yup.string().min(2, "Too Short!").max(50, "Too Long!").strict(),
})
export const SearchBar = ({
  extended = true,
  data,
  setTribute,
  filterMode,
  setFilterMode,
  setLetter,
  geocodes,
  setGeocodes,
}) => {
  const [open, setOpen] = useState(false)
  const [ageValue, setAgeValue] = useState({ min: 18, max: 90 })
  const [milesValue, setMilesValue] = useState(60)
  const [status, setStatus] = useState(2)
  const [location, setLocation] = useState("United States")
  const [advanceApplied, setAdvanceApplied] = useState(false)
  const advanceSearchDropdownRef = useRef(null)
  useOutsideAlerter(advanceSearchDropdownRef, setOpen)

  const simpleFilter = values => {
    let filteredData = data
      .filter(item => {
        const concatName = `${item.node.mom.firstName} ${item.node.mom.lastName}`
        return concatName.toLowerCase().includes(values.name.toLowerCase())
      })
      .filter(item =>
        item.node.location.toLowerCase().includes(values.location.toLowerCase())
      )

    if (advanceApplied) {
      let { min, max } = ageValue
      filteredData = filteredData.filter(item => {
        let age = parseInt(item.node.mom.birthDate.split(" years ago")[0])
        if (age >= min && age <= max) {
          return item
        }
      })
      if (status === 0) {
        filteredData = filteredData.filter(item => item.node.alive)
      }
      if (status === 1) {
        filteredData = filteredData.filter(item => !item.node.alive)
      }

      if (milesValue <= 100) {
        if (geocodes.length > 1) {
          filteredData = filteredData.filter(item => {
            let latitudeNum = Number(Number(item.node.latitude).toFixed(2))
            let longitudeNum = Number(Number(item.node.longitude).toFixed(2))
            let locationLatitude = Number(geocodes[1].toFixed(2))
            let locationLongitude = Number(geocodes[0].toFixed(2))
            const distance = getDistanceFromLatLonInMiles(
              latitudeNum,
              longitudeNum,
              locationLatitude,
              locationLongitude
            )
            return distance <= milesValue
          })
        }
      }

      setFilterMode(true)
    }
    return setTribute(filteredData)
  }

  const handleFilter = values => {
    simpleFilter(values)
  }
  const statuses = ["Show Living", "Show Deceased", "Show All"]
  const formSubmit = values => {
    handleFilter(values)
    extended && setLetter(null)
  }

  const removeFilters = () => {
    setFilterMode(false)
    setAdvanceApplied(false)
    setStatus(2)
    setTribute(data)
  }

  // Get user's current Location
  const getUserLocation = () => {
    const onSuccess = obj => {
      const { coords } = obj
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.longitude},${coords.latitude}.json?access_token=${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}`
      )
        .then(response => response.json())
        .then(result => {
          const location = `${result.features[1].text}, ${result.features[2].text}`
          setGeocodes(result.query)
          setLocation(location)
        })
    }
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(onSuccess, console.log)
    }
  }

  useEffect(() => {
    getUserLocation()
  }, [])

  return (
    <div
      className={`${styles.searchBar} w-full lg:flex flex-col justify-between items-center`}
    >
      <Formik
        initialValues={{
          name: "",
          location: "",
        }}
        validationSchema={SearchFilterSchema}
        onSubmit={formSubmit}
      >
        {({ errors, touched, values, validateForm, setFieldValue }) => {
          return (
            <Form className="md:flex w-full">
              <SearchInput
                inputType="name"
                name="name"
                id="name"
                error={errors.name ? true : false}
                clear={setFieldValue}
                clearBtn={values.name !== "" ? true : false}
              />
              <SearchInput
                inputType="location"
                name="location"
                error={errors.location ? true : false}
                clear={setFieldValue}
                clearBtn={values.location !== "" ? true : false}
              />
              <div
                className={`${styles.advanceSearch} ${
                  open ? styles.active : ""
                } flex justify-end`}
                ref={advanceSearchDropdownRef}
                onClick={() => setOpen(prev => !prev)}
              >
                Advanced search
                {open ? (
                  <SearchDropdownHoverIcon className="ml-2" />
                ) : (
                  <SearchDropdownIcon className="ml-2" />
                )}
                <div
                  className={`${styles.advanceSearchPopup} ${
                    open ? styles.active : ""
                  }`}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <div className={styles.section}>
                    <h4>
                      Age Range:{" "}
                      <span>
                        {ageValue.min < 18 && ageValue.max <= 90
                          ? `Up to ${ageValue.max} years old`
                          : ageValue.min > 18 && ageValue.max > 90
                          ? `Over ${ageValue.min} years old`
                          : ageValue.min < 18 && ageValue.max > 90
                          ? "Any age"
                          : `${ageValue.min} - ${ageValue.max} y.o`}
                      </span>
                    </h4>
                    <div className="mt-3">
                      <InputRange
                        maxValue={91}
                        minValue={17}
                        value={ageValue}
                        onChange={value => setAgeValue(value)}
                      />
                    </div>
                    <div
                      className={`${styles.label} flex justify-between items-center`}
                    >
                      <span>{ageValue.min < 18 ? "No limit" : "18 y.o"}</span>
                      <span>{ageValue.max > 90 ? "No maximum" : "90 y.o"}</span>
                    </div>
                  </div>
                  <div className={styles.section}>
                    <h4>
                      My Location: <span>{location}</span>
                    </h4>
                    <button
                      type="button"
                      onClick={() =>
                        Swal.fire({
                          title: "Enter Zipcode",
                          input: "number",
                          inputAttributes: {
                            autocapitalize: "off",
                          },
                          showCancelButton: true,
                          confirmButtonText: "Look up",
                          showLoaderOnConfirm: true,
                          preConfirm: zip => {
                            return fetch(
                              `https://public.opendatasoft.com/api/records/1.0/search/?dataset=us-zip-code-latitude-and-longitude&q=&facet=state&facet=timezone&facet=dst&refine.zip=${zip}`
                            )
                              .then(response => {
                                if (!response.ok) {
                                  throw new Error(response.statusText)
                                }
                                return response.json()
                              })
                              .catch(error => {
                                Swal.showValidationMessage(
                                  `Request failed: ${error}`
                                )
                              })
                          },
                          allowOutsideClick: () => !Swal.isLoading(),
                        }).then(result => {
                          if (result.isConfirmed) {
                            if (result.value.records.length > 0) {
                              const location = `${result.value.records[0].fields.city}, ${result.value.records[0].fields.state}`
                              const geocode = [
                                result.value.records[0].fields.longitude,
                                result.value.records[0].fields.latitude,
                              ]
                              setLocation(location)
                              setGeocodes(geocode)
                            } else {
                              Swal.fire({
                                icon: "error",
                                title: "Zipcode is invalid!",
                                showConfirmButton: false,
                                timer: 1500,
                              })
                            }
                          }
                        })
                      }
                      className={styles.locationBtn}
                    >
                      Change Location
                      <span>
                        <PinPoint />
                      </span>
                    </button>
                    <h4>
                      Max distance:{" "}
                      <span>
                        {milesValue >= 100
                          ? "No maximum"
                          : `${milesValue} miles`}
                      </span>
                    </h4>
                    <div className="mt-3">
                      <InputRange
                        maxValue={101}
                        minValue={20}
                        value={milesValue}
                        onChange={value => setMilesValue(value)}
                      />
                    </div>
                  </div>
                  <div className={styles.section}>
                    {statuses.map((btn, index) => (
                      <div key={index} className={styles.radioBtn}>
                        <input
                          type="radio"
                          id={`living-${btn}`}
                          defaultChecked
                          name="status"
                        />
                        <label
                          htmlFor={`living-${btn}`}
                          onClick={() => setStatus(index)}
                          className={status === index ? styles.active : ""}
                        >
                          {btn}
                        </label>
                        <span
                          onClick={() => setStatus(index)}
                          className={`${styles.checkmark} ${
                            status === index ? styles.active : ""
                          }`}
                        ></span>
                      </div>
                    ))}
                  </div>
                  <div className={styles.applyBtn}>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault()
                        setAdvanceApplied(true)
                        setOpen(false)
                      }}
                      className="btn twine-gradient"
                    >
                      Apply Settings
                    </button>
                  </div>
                </div>
              </div>
              <button
                onClick={() => validateForm().then(() => formSubmit(values))}
                type="submit"
                className="btn twine-gradient"
              >
                Find Now
              </button>
            </Form>
          )
        }}
      </Formik>
      {advanceApplied && (
        <div className={`w-full ${styles.appliedFilters}`}>
          <hr />
          <div className="flex flex-wrap justify-between items-center">
            <div className={`${styles.filter} flex items-center`}>
              <h5 className={styles.title}>Age Range:</h5>
              <p className={styles.value}>
                {ageValue.min < 18 && ageValue.max <= 90
                  ? `Up to ${ageValue.max} years old`
                  : ageValue.min > 18 && ageValue.max > 90
                  ? `Over ${ageValue.min} years old`
                  : ageValue.min < 18 && ageValue.max > 90
                  ? "Any age"
                  : `${ageValue.min} - ${ageValue.max} y.o`}
              </p>
            </div>
            <div className={`${styles.filter} flex items-center`}>
              <h5 className={styles.title}>My Location:</h5>
              <p className={styles.value}>{location}</p>
            </div>
            <div className={`${styles.filter} flex items-center`}>
              <h5 className={styles.title}>Max Distance:</h5>
              <p className={styles.value}>
                {milesValue >= 100 ? "No maximum" : `${milesValue} miles`}
              </p>
            </div>
            <div
              className={styles.clearFilters}
              onClick={() => removeFilters(false)}
            >
              Clear All Filters
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export const ExtendedFilter = ({
  setTribute,
  sortData,
  data,
  activeLetter,
  setLetter,
  geocodes,
}) => {
  const [sort, setSort] = useState("Most Recent")
  const sortOptions = ["Most Recent", "Last Name", "Age", "Distance"].filter(
    option => option !== sort
  )
  let tempData = [...sortData]

  useEffect(() => {
    if (sort === "Last Name") {
      tempData.sort((a, b) => {
        if (
          a.node.mom.lastName.toLowerCase() < b.node.mom.lastName.toLowerCase()
        )
          return -1
        if (
          a.node.mom.lastName.toLowerCase() > b.node.mom.lastName.toLowerCase()
        )
          return 1
        return 0
      })
      setTribute(tempData)
    } else if (sort === "Age") {
      tempData.sort((a, b) => {
        if (
          a.node.mom.birthDate.split(" ")[0] <
          b.node.mom.birthDate.split(" ")[0]
        )
          return -1
        if (
          a.node.mom.birthDate.split(" ")[0] >
          b.node.mom.birthDate.split(" ")[0]
        )
          return 1
        return 0
      })
      setTribute(tempData)
    } else if (sort === "Distance") {
      tempData.sort((a, b) => {
        const latA = Number(a.node.latitude).toFixed(2)
        const longA = Number(a.node.longitude).toFixed(2)
        const latB = Number(b.node.latitude).toFixed(2)
        const longB = Number(b.node.longitude).toFixed(2)

        const distanceA = getDistanceFromLatLonInMiles(
          geocodes[1],
          geocodes[0],
          latA,
          longA
        )
        const distanceB = getDistanceFromLatLonInMiles(
          geocodes[1],
          geocodes[0],
          latB,
          longB
        )

        if (distanceA < distanceB) return -1
        if (distanceA > distanceB) return 1
        return 0
      })
      setTribute(tempData)
    } else if (sort === "Most Recent") {
      tempData.sort((a, b) => {
        if (a.node.createdAt < b.node.createdAt) return 1
        if (a.node.createdAt > b.node.createdAt) return -1
        return 0
      })
      setTribute(tempData)
    }
  }, [sort])

  return (
    <div className="flex flex-col md:flex-row justify-between items-center mb-8 md:px-2">
      <AlphabeticalFilter
        data={data}
        setTribute={setTribute}
        activeLetter={activeLetter}
        setLetter={setLetter}
      />
      <Dropdown
        noOutline
        classes={styles.dropdown}
        defaultValue={`<span>Sort by:</span> ${sort}`}
        options={sortOptions}
        changeSelected={setSort}
      />
    </div>
  )
}
