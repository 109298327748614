import React, { useState } from "react"
import { Link } from "gatsby"

// Components
import { SearchBar } from "../../../components/searchBar"
import TributeCard from "../../../components/tributeCard"

// Stylesheet
import styles from "./index.module.scss"

// Images
import stroke from "../../../images/stroke2.svg"

export const TributesList = ({ data, limitTributes }) => (
  <div
    className={`${styles.tributeListContainer} w-full h-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-0 md:gap-16 xl:gap-20`}
  >
    {!data.length && <h3>Tributes not found</h3>}
    {data?.slice(0, limitTributes).map((tribute, index) => (
      <TributeCard key={index} data={tribute} />
    ))}
  </div>
)

const TributeSection = ({ data: { title, details, data } }) => {
  const [tributes, setTribute] = useState(data)
  const [filteredTributes, setFilteredTributes] = useState(data)
  const [filterMode, setFilterMode] = useState(false)
  const [geocodes, setGeocodes] = useState([0, 0])

  return (
    <div className={styles.tributeSection}>
      <img src={stroke} className={styles.stroke} />
      <div className={styles.gradient}>
        <div className="max-w-6xl mx-auto px-4 xl:px-0 h-full flex flex-col justify-center items-center">
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subTitle}>{details}</p>

          <SearchBar
            data={filteredTributes}
            setTribute={setTribute}
            filterMode={filterMode}
            setFilterMode={setFilterMode}
            geocodes={geocodes}
            setGeocodes={setGeocodes}
            extended={false}
          />
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 xl:px-0 h-full flex flex-col justify-center items-center">
        <div className="w-full md:mt-12 mb-10 md:mb-12">
          <TributesList data={tributes} limitTributes={6} />
        </div>
        <Link to="/tributes" className="btn dark">
          Show More Moms
        </Link>
      </div>
    </div>
  )
}

export default TributeSection
